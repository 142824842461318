import React, {useEffect} from 'react';
import style from './WdExport.module.scss';
import EmployeeExport from "./EmployeeExport/EmployeeExport";
import LockData from "./LockData/LockData";
import ClientExport from "./ClientExport/ClientExport";
import {useDispatch} from "react-redux";
import {getAllUsers} from "../../store/actions/MngData";

const WdExport = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUsers())
    }, []);

    return <div className={style.wdExport}>
        <LockData/>
        <EmployeeExport/>
        <ClientExport/>
    </div>
}

export default WdExport