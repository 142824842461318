import React, {useState} from "react";
import style from './RateTimeCase.module.scss'
import {
    Autocomplete,
    Checkbox,
    Divider,
    Fab,
    FormControlLabel,
    Paper,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material";
import {NumberFormatCustom, PercentFormatCustom} from "../../../Library/Utils/Utils";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers-pro";
import 'dayjs/locale/it';
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {fromJS} from "immutable";
import dayjs from "dayjs";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ConfirmPopUp from "../../../Library/ConfirmPopUp/ConfirmPopUp";

const RateTimeCase = ({rateTime, regions, onChange, onDelete, onDuplicate}) => {

    const [popUpDeleteRateTimeCase, setPopUpDeleteRateTimeCase] = useState(false);

    const id = rateTime.get('tempId', null) || rateTime.get('id')

    const opUpdate = (key, value) => {
        onChange(id, key, value)
    }

    let tripType = []
    switch (rateTime.get('trip_type')) {
        case 1:
            tripType = ['fs'];
            break;
        case 2:
            tripType = ['trs'];
            break;
        case 3:
            tripType = ['fs', 'trs'];
            break;
        default:
            tripType = [];
            break;
    }

    let validity = []
    switch (rateTime.get('validity')) {
        case 1:
            validity = ['work'];
            break;
        case 2:
            validity = ['travel'];
            break;
        case 3:
            validity = ['work', 'travel'];
            break;
        default:
            validity = [];
            break;
    }


    return <>
        <Paper className={style.rateTimeCase}>
            <div className={style.configuration}>

                <Autocomplete
                    id="regions"
                    className={style.regions}
                    options={regions.map(r => r.get('name')).toJS()}
                    value={rateTime.get('regions').map(r => r.get('name')).toJS()}
                    multiple
                    disableCloseOnSelect
                    disablePortal
                    size="small"
                    onChange={(event, newValue) => {
                        const _new = fromJS(newValue.map(r => regions.find(_r => _r.get('name') === r)))
                        opUpdate('regions', _new)
                    }}
                    renderInput={(params) => <TextField {...params} label="Area geografica"/>}
                />
                {/*<Select*/}
                {/*    id="regions"*/}
                {/*    className={style.regions}*/}
                {/*    value={rateTime.get('regions').toJS()}*/}
                {/*    multiple*/}
                {/*    label="Area geografica"*/}
                {/*    // onChange={(event) => opUpdate('region_id', event.target.value)}*/}
                {/*    onChange={event => console.debug(event.value)}*/}
                {/*    size={"small"}*/}

                {/*>*/}
                {/*    {regions?.map((option) => (<MenuItem key={option.get('id')} value={option.get('id')}>*/}
                {/*        {option.get('name')}*/}
                {/*    </MenuItem>))}*/}
                {/*</Select>*/}
                <ToggleButtonGroup
                    id={'fs/trs'}
                    className={style.selectors}
                    value={rateTime.get('trip_type')?.toJS() || []}
                    onChange={(e, value) => opUpdate('trip_type', fromJS(value))}
                    // value={rateTime.get('validity')}
                    // onChange={(event, value) => {
                    //     if (value.includes('fs') && value.includes('trs')) {
                    //         value = 3;
                    //     } else if (value.includes('fs')) {
                    //         value = 1;
                    //     } else if (value.includes('trs')) {
                    //         value = 2;
                    //     } else {
                    //         value = 0;
                    //     }
                    //     opUpdate('trip_type', value)
                    // }}
                    color={'secondary'}
                    size={"small"}
                >
                    <ToggleButton value={1} sx={{width: 120}}>
                        Nostra sede
                    </ToggleButton>
                    <ToggleButton value={2} sx={{width: 50}}>
                        FS
                    </ToggleButton>
                    <ToggleButton value={3} sx={{width: 50}}>
                        Trs
                    </ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup
                    id={'validity'}
                    className={style.selectors}
                    value={validity}
                    onChange={(event, value) => {
                        if (value.includes('work') && value.includes('travel')) {
                            value = 3;
                        } else if (value.includes('work')) {
                            value = 1;
                        } else if (value.includes('travel')) {
                            value = 2;
                        } else {
                            value = 0;
                        }
                        opUpdate('validity', value)
                    }}
                    color={'secondary'}
                    size={"small"}
                >
                    <ToggleButton value="work" sx={{width: 100}}>
                        Lavoro
                    </ToggleButton>
                    <ToggleButton value="travel" sx={{width: 100}}>
                        Viaggio
                    </ToggleButton>
                </ToggleButtonGroup>
                <div className={style.action}>
                    {/*<Tooltip title={'Aggiungi'} className={style.button}>*/}
                    {/*    <Fab size="small" color="secondary">*/}
                    {/*        <AddRoundedIcon/>*/}
                    {/*    </Fab>*/}
                    {/*</Tooltip>*/}
                    <Tooltip title={'Elimina'} className={style.button}>
                        <Fab size="small" color="secondary" onClick={() => setPopUpDeleteRateTimeCase(true)}>
                            <RemoveRoundedIcon/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title={'Duplica'} className={style.button}>
                        <Fab size="small" color="secondary" onClick={() => onDuplicate(id)}>
                            <ContentCopyRoundedIcon/>
                        </Fab>
                    </Tooltip>
                </div>
            </div>
            <Divider className={style.divider}/>
            <div className={style.row}>
                <ToggleButtonGroup
                    id={'type'}
                    className={style.selectors}
                    value={rateTime.get('type')}
                    onChange={(event, value) => {
                        if (value !== null) {
                            opUpdate('type', value)
                        }
                    }}
                    color={'secondary'}
                    size={"small"}
                    exclusive
                >
                    <ToggleButton value={1} sx={{width: 100}}>
                        Orario
                    </ToggleButton>
                    <ToggleButton value={2} sx={{width: 100}}>
                        Giornaliero
                    </ToggleButton>
                </ToggleButtonGroup>

                {rateTime.get('type') === 2 &&
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                        <TimePicker
                            id={'ordinaryHour'}
                            value={dayjs(`1970-01-01T${rateTime.get('avg_hour_per_day')}`)}
                            onChange={(value) => opUpdate('avg_hour_per_day', value?.format('HH:mm:ss'))}
                            className={style.time}
                            label={'Media ore al giorno'}
                            renderInput={(params) => <TextField {...params} size={"small"}/>}
                        />
                    </LocalizationProvider>
                }
            </div>

            <TextField
                id={'ordinaryRate'}
                label={'Tariffa ordinaria'}
                value={rateTime.get('ordinary_rate')}
                className={style.money}
                size={'small'}
                InputProps={{inputComponent: NumberFormatCustom}}
                onChange={(event) => opUpdate('ordinary_rate', event.target.value)}
            />
            <TextField
                id={'extraordinaryRate'}
                label={'Tariffa straordinaria'}
                value={rateTime.get('extraordinary_rate')}
                className={style.money}
                size={'small'}
                InputProps={{inputComponent: NumberFormatCustom}}
                onChange={(event) => opUpdate('extraordinary_rate', event.target.value)}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                <TimePicker
                    id={'ordinaryHour'}
                    value={dayjs(`1970-01-01T${rateTime.get('ordinary_hour')}`)}
                    onChange={(value) => opUpdate('ordinary_hour', value?.format('HH:mm:ss'))}
                    className={style.time}
                    label={'Max. ore ordinarie'}
                    renderInput={(params) => <TextField {...params} size={"small"}/>}
                />
            </LocalizationProvider>


            <div className={style.inline}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'it'}>
                    <TimePicker
                        id={'startNightSlot'}
                        value={dayjs(`1970-01-01T${rateTime.get('start_day_slot')}`)}
                        onChange={(value) => opUpdate('start_day_slot', value?.format('HH:mm:ss'))}
                        className={style.time}
                        label={'Inizio fascia diurna'}
                        renderInput={(params) => <TextField {...params} size={'small'}/>}
                    />
                    <TimePicker
                        id={'endNightSlot'}
                        value={dayjs(`1970-01-01T${rateTime.get('end_day_slot')}`)}
                        onChange={(value) => opUpdate('end_day_slot', value?.format('HH:mm:ss'))}
                        className={style.time}
                        label={"Fine fascia diurna"}
                        renderInput={(params) => <TextField {...params} size={'small'}/>}
                    />
                </LocalizationProvider>
                <div>
                    <TextField
                        id={'nightSupplement'}
                        label={'Maggiorazione notturna'}
                        className={style.money}
                        size={'small'}
                        value={rateTime.get('night_supplement')}
                        onChange={(event) => opUpdate('night_supplement', event.target.value)}
                        InputProps={{inputComponent: rateTime.get('night_supplement_type') === 1 ? NumberFormatCustom : PercentFormatCustom}}
                    />

                    <ToggleButtonGroup
                        id={'nightSupplementType'}
                        className={style.selectors}
                        value={rateTime.get('night_supplement_type')}
                        onChange={(event, value) => {
                            if (value !== null) {
                                opUpdate('night_supplement_type', value)
                            }
                        }}
                        color={'secondary'}
                        size={"small"}
                        exclusive
                    >
                        <ToggleButton value={1} sx={{width: 50}}>
                            €
                        </ToggleButton>
                        <ToggleButton value={2} sx={{width: 50}}>
                            %
                        </ToggleButton>
                    </ToggleButtonGroup>


                </div>
            </div>

            <div className={style.inline}>
                <ToggleButtonGroup
                    id={'validity_days'}
                    className={style.selectors}
                    value={rateTime.get('validity_days').toJS()}
                    onChange={(e, value) => opUpdate('validity_days', fromJS(value))}
                    color={'secondary'}
                    size={"small"}
                    style={{width: 410, display: 'flex'}}
                >
                    <ToggleButton value={1} sx={{flex: '1'}}>
                        Lun
                    </ToggleButton>
                    <ToggleButton value={2} sx={{flex: '1'}}>
                        Mar
                    </ToggleButton>
                    <ToggleButton value={3} sx={{flex: '1'}}>
                        Mer
                    </ToggleButton>
                    <ToggleButton value={4} sx={{flex: '1'}}>
                        Gio
                    </ToggleButton>
                    <ToggleButton value={5} sx={{flex: '1'}}>
                        Ven
                    </ToggleButton>
                    <ToggleButton value={6} sx={{flex: '1'}}>
                        Sab
                    </ToggleButton>
                    <ToggleButton value={7} sx={{flex: '1'}}>
                        Dom
                    </ToggleButton>
                </ToggleButtonGroup>

                <div>
                    <TextField
                        id={'holidaySupplement'}
                        label={'Maggiorazione festiva'}
                        className={style.money}
                        size={'small'}
                        value={rateTime.get('holiday_supplement')}
                        onChange={(event) => opUpdate('holiday_supplement', event.target.value)}
                        InputProps={{inputComponent: rateTime.get('holiday_supplement_type') === 1 ? NumberFormatCustom : PercentFormatCustom}}
                    />

                    <ToggleButtonGroup
                        id={'holidaySupplementType'}
                        className={style.selectors}
                        value={rateTime.get('holiday_supplement_type')}
                        onChange={(event, value) => {
                            if (value !== null) {
                                opUpdate('holiday_supplement_type', value)
                            }
                        }}
                        color={'secondary'}
                        size={"small"}
                        exclusive
                    >
                        <ToggleButton value={1} sx={{width: 50}}>
                            €
                        </ToggleButton>
                        <ToggleButton value={2} sx={{width: 50}}>
                            %
                        </ToggleButton>
                    </ToggleButtonGroup>


                </div>
            </div>
            <FormControlLabel
                value="end"
                checked={rateTime.get('all_inclusive')}
                onChange={event => opUpdate('all_inclusive', event.target.checked)}
                control={<Checkbox/>}
                label="Spese incluse"
                labelPlacement="end"

            />

            <TextField
                id={'extraBusinessAgreements'}
                label={'Accordi aggiuntivi'}
                value={rateTime.get('extra_business_agreements')}
                onChange={(event) => opUpdate('extra_business_agreements', event.target.value)}
                multiline
                minRows={2}
                maxRows={5}
                fullWidth
            />
        </Paper>

        <ConfirmPopUp
            open={popUpDeleteRateTimeCase}
            handleAccept={() => onDelete(id)}
            handleDecline={() => setPopUpDeleteRateTimeCase(false)}
            title={`Eliminazione opzione`}
            description={`Sei siscuro di voler eliminare questa opzione?`}
            ko={'Annulla'}
            ok={'Conferma'}
        />
    </>
}

export default RateTimeCase;