import React from "react";
import './WorkList.scss'
import {connect} from "react-redux";
import {
    getWorkData,
    onPurchAdd,
    onPurchChangeHandler,
    onPurchDelete,
    onTrvAdd,
    onTrvChangeHandler,
    onTrvDelete,
    onWDAdd,
    onWdCopy,
    onWdPaste,
    onWorkDataChange,
    onWorkDataDelete,
    onWorkTimeAdd,
    onWorkTimeChange,
    onWorkTimeDelete,
    onWtTaskAdd,
    onWtTaskChangeHandler,
    onWtTaskDelete,
} from "../../store/actions/WorkData";
import Day from "../Library/Day/Day";
import {ButtonBase, Paper, Skeleton} from "@mui/material";
import WorkData from "./WorkData/WorkData";
import Typography from "@mui/material/Typography";
import {addDays, totCommHour} from "../Library/Utils/Utils";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import PasteIcon from "../Library/Icons/PasteIcon";
import {Map} from 'immutable'
import EditWorkData from "../Library/EditWorkData/EditWorkData";
import moment from "moment";
import WorkToolbar from "./WorkToolbar/WorkToolbar";
import Leave from "./Leave/Leave";

function lastWorkDay(year, month) {
    var lastDay = new Date(year, parseInt(month) + 1, 0);
    if (lastDay.getDay() > 5) {
        lastDay.setDate(lastDay.getDate() - (lastDay.getDay() - 5));
    }
    return lastDay;
}

class WorkList extends React.Component {

    state = {
        selectedDay: moment().format('DD-MM-YYYY'),
        editWd: Map(),
        forceRefresh: false
    }

    onChangeDay = (day) => {
        this.setState({selectedDay: day})
    }

    nextWeekClickHandle = () => {
        const newDate = addDays(new Date(this.props.days.getIn([13, 'day'])), 1)
        this.props.get_work_data(newDate, 7).then(() =>
            this.setState({selectedDay: moment(newDate.toDateString()).startOf('isoweek').format('DD-MM-YYYY')})
        )
    }

    prevWeekClickHandle = () => {
        const newDate = new Date(this.props.days.getIn([0, 'day']))
        this.props.get_work_data(newDate, 7).then(() =>
            this.setState({selectedDay: moment(newDate.toDateString()).startOf('isoweek').format('DD-MM-YYYY')})
        )
    }

    forceEditWd = (wd) => {
        this.setState({editWd: wd})
    }

    handleWdForceClose = () => {
        this.setState({editWd: Map(), forceRefresh: true})
    }

    handleAddWorkdata = (idxDay, day, locked) => {
        this.props.onWDAdd(idxDay, day, locked)
            .then(res => {
                if (locked) {
                    this.forceEditWd(res)
                }
            })
    }

    handleRemoveWorkdata = (idxDay, idxComm, idComm, locked) => {
        this.props.RemoveComm(idxDay, idxComm, idComm)
            .then(res => {

            })
    }

    render() {
        let days
        let data
        let info
        let totFirstWeek = 0
        let totSecondWeek = 0
        let lockedDay = false
        let confirmedDay = true
        let editedDay = false
        let paidLeave = false
        if (this.props.days.size > 0 && !this.state.forceRefresh) {
            days = this.props.days.map((d, idxDay) => {
                if (moment(d.get('day')).format('DD-MM-YYYY') === this.state.selectedDay) {
                    data = d.get('workdata').map((c, index) => {
                        lockedDay = lockedDay || c.get('locked', 0) >= 20
                        editedDay = editedDay || (c.get('locked', 0) > 0 && c.get('locked', 0) < 10)
                        confirmedDay = confirmedDay && c.get('locked', 0) === 10
                        if (c.get('paid_leave') && c.get('paid_leave') !== 0) {
                            paidLeave = true
                            return <Leave
                                workdata={c}
                                idx_day={idxDay}
                                idx_comm={index}
                            />
                        } else {
                            return <WorkData
                                key={c.get('id')}
                                workdata={c}
                                lockedData={c.get('locked')}
                                idx_day={idxDay}
                                idx_comm={index}
                                date={this.props.day}
                                listWorkedCommissions={this.props.listWorkedCommissions}
                                listUserFdl={this.props.fdls}
                                mngData={this.props.mngData}
                                machines={this.props.machines}
                                onWDAdd={this.handleAddWorkdata}
                                RemoveComm={this.handleRemoveWorkdata}
                                onPropsCommChangeHandler={this.props.onPropsCommChangeHandler}
                                AddTravel={this.props.AddTravel}
                                RemoveTravel={this.props.RemoveTravel}
                                onPropsTravelChangeHandler={this.props.onPropsTravelChangeHandler}
                                AddPurch={this.props.AddPurch}
                                RemovePurch={this.props.RemovePurch}
                                onPropsPurchChangeHandler={this.props.onPropsPurchChangeHandler}
                                onWtTaskAdd={this.props.onWtTaskAdd}
                                onWtTaskDelete={this.props.onWtTaskDelete}
                                onWtTaskChangeHandler={this.props.onWtTaskChangeHandler}
                                onWorkTimeAdd={this.props.onWorkTimeAdd}
                                onWorkTimeDelete={this.props.onWorkTimeDelete}
                                onWorkTimeChange={this.props.onWorkTimeChange}
                                onCopy={this.props.onWdCopy}
                                onPaste={this.props.onWdPaste}
                                user={this.props.user}
                                onForceEdit={this.forceEditWd}
                                countries={this.props.countries}
                            />
                        }
                    })
                }

                let tot = 0
                let totTravel = 0
                let confirmed = true
                let locked = true
                d.get('workdata').forEach(c => {
                    confirmed = confirmed && c.get('locked') >= 10;
                    locked = locked && c.get('locked') < 0;
                    if (c.get('nc') !== '') {
                        c.get('worktime').forEach(wt => {
                            tot = tot + totCommHour(wt.get('start'), wt.get('end'))
                        })
                        c.get('travels').forEach(t => {
                            totTravel = totTravel + totCommHour(t.get('start'), t.get('end'))
                        })
                    }
                })
                if (idxDay < 7) {
                    totFirstWeek = totFirstWeek + tot
                } else {
                    totSecondWeek = totSecondWeek + tot
                }

                return <Day
                    key={d.get('day')}
                    first={idxDay === 0}
                    prevWeekClickHandle={() => this.prevWeekClickHandle()}
                    last={idxDay === 13}
                    nextWeekClickHandle={this.nextWeekClickHandle}
                    day={d}
                    totDay={tot}
                    totTravel={totTravel}
                    confirmed={confirmed}
                    locked={locked}
                    selectedDay={this.state.selectedDay}
                    onClick={this.onChangeDay}
                />
            })
            info = (
                <React.Fragment>
                    <Typography>{this.props.days.getIn([0, 'day']).slice(8, 10)}/{this.props.days.getIn([6, 'day']).slice(8, 10)} = {Math.round(totFirstWeek * 100) / 100}h</Typography>
                    <Typography>{this.props.days.getIn([7, 'day']).slice(8, 10)}/{this.props.days.getIn([13, 'day']).slice(8, 10)} = {Math.round(totSecondWeek * 100) / 100}h</Typography>
                </React.Fragment>
            )
        } else {
            days = <React.Fragment>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
                <Skeleton variant='rect' width={'100%'} height={'100%'}/>
            </React.Fragment>
        }


        if (this.state.forceRefresh) {
            //Force reload all workdata, this is for reload default value of workdata after force edit
            this.setState({forceRefresh: false})
        }

        // const lastDayOfMonth = moment().endOf('month').format('DD-MM-YYYY') === this.state.selectedDay;
        const lastDayOfMonth = moment(lastWorkDay(moment().year(), moment().month())).format('DD-MM-YYYY') === this.state.selectedDay;
        return (
            <React.Fragment>
                {/*<DragAndDrop handleDrop={this.handleDrop} dragCounter={0}>*/}
                <div className={'WorkList'}>
                    <div className={'days'}>
                        {days}
                        <ButtonBase className={'info'} disableRipple={true}>
                            <Paper className={'infoContent'}>
                                <Typography variant={"h4"}>info</Typography>
                                {info}
                            </Paper>
                        </ButtonBase>
                    </div>
                    <div className={'commission'}>
                        <ContextMenuTrigger id={'workdataListContextMenu'}>
                            {/*{lastDayOfMonth &&*/}
                            {/*    <Alert severity="error" style={{marginBottom: '10px'}} variant={"filled"}>*/}
                            {/*        Ultimo giorno del mese, caricare tutte le ore*/}
                            {/*    </Alert>*/}
                            {/*}*/}
                            <WorkToolbar
                                day={moment(this.state.selectedDay, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                                idxDay={this.props.days.findIndex(d => d.get('day') === moment(this.state.selectedDay, 'DD-MM-YYYY').format('YYYY-MM-DD'))}
                                lockedDay={lockedDay}
                                confirmedDay={confirmedDay}
                                locked={confirmedDay}
                                editedDay={editedDay}
                                lastDay={lastDayOfMonth}
                                paidLeaveInserted={paidLeave}
                            />
                            {data || ''}
                        </ContextMenuTrigger>
                        <ContextMenu id={`workdataListContextMenu`}>
                            <MenuItem
                                onClick={() => this.props.onWdPaste(this.props.days.findIndex(d => moment(d.get('day')).format('DD-MM-YYYY') === this.state.selectedDay))}>
                                <PasteIcon className="paste"/>
                                <span>Paste</span>
                            </MenuItem>
                        </ContextMenu>
                    </div>
                    <EditWorkData
                        open={Boolean(this.state.editWd.get('id'))}
                        workdata={this.state.editWd}
                        onClose={this.handleWdForceClose}
                    />
                </div>
                {/*</DragAndDrop>*/}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        days: state.data.day,
        listWorkedCommissions: state.userData.list_worked_commissions,
        mngData: state.mng_data.mng_data,
        machines: state.mng_data.machines,
        fdls: state.userData.fdls,
        countries: state.meta.countries,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        get_work_data: (...args) => dispatch(getWorkData(...args)),
        onWDAdd: (...args) => dispatch(onWDAdd(...args)),
        RemoveComm: (...args) => dispatch(onWorkDataDelete(...args)),
        onPropsCommChangeHandler: (...args) => dispatch(onWorkDataChange(...args)),
        AddTravel: (...args) => dispatch(onTrvAdd(...args)),
        AddPurch: (...args) => dispatch(onPurchAdd(...args)),
        onWtTaskAdd: (...args) => dispatch(onWtTaskAdd(...args)),
        RemovePurch: (...args) => dispatch(onPurchDelete(...args)),
        onPropsPurchChangeHandler: (...args) => dispatch(onPurchChangeHandler(...args)),
        RemoveTravel: (...args) => dispatch(onTrvDelete(...args)),
        onPropsTravelChangeHandler: (...args) => dispatch(onTrvChangeHandler(...args)),
        onWtTaskDelete: (...args) => dispatch(onWtTaskDelete(...args)),
        onWtTaskChangeHandler: (...args) => dispatch(onWtTaskChangeHandler(...args)),
        onWorkTimeAdd: (...args) => dispatch(onWorkTimeAdd(...args)),
        onWorkTimeDelete: (...args) => dispatch(onWorkTimeDelete(...args)),
        onWorkTimeChange: (...args) => dispatch(onWorkTimeChange(...args)),
        onWdPaste: (...args) => dispatch(onWdPaste(...args)),
        onWdCopy: (...args) => dispatch(onWdCopy(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkList)