import React, {useEffect} from "react";
import {useLocation} from "react-router-dom"
import {connect, useDispatch} from 'react-redux'
import AppBar from '@mui/material/AppBar';
import {Button, DialogContent, Drawer, IconButton, Toolbar, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DateFnsUtils from "@date-io/date-fns";
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import DatePickerWeek from "../components/Library/DatePicker/DatePickerWeek"
import "./Layout.scss"
import {
    downloadFdl,
    generateCleanPdf,
    generateFdl,
    getEventWorktime,
    getUserFdl,
    getWorkData,
    lockDays,
} from '../store/actions/WorkData'
import FdlDialog from "./FdlDialog/FdlDialog";
import {get_statistics, get_userDataComm, get_worked_commissions} from "../store/actions/userData";
import {get_machines_list, get_mng_data, getCommissionCodes, getWorkdataAggregation} from "../store/actions/MngData";
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import {logoutUser} from "../store/actions/auth";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {withRouter} from "react-router";
import UserPopUp from "../components/Library/UserPopUp/UserPopUp";
import FeaturePopUp from "../components/Library/FeaturePopUp/FeaturePopUp";
import {withMedia} from "react-media-query-hoc";
import WeekCompilationPopUp from "../components/Library/WeekCompilationPopUp/WeekCompilationPopUp";
import FldIcon from "../components/Library/Icons/FldIcon";
import Dialog from "@mui/material/Dialog";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {fromJS, List, Map} from "immutable";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Divider from "@mui/material/Divider";
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns';
import {useTheme} from "@mui/styles";
import {get_countries, get_regions, get_themes} from "../store/actions/meta";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import {ENQUEUE_SNACKBAR} from "../store/actions/action";
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import RawOnRoundedIcon from '@mui/icons-material/RawOnRounded';

const MenuItem = ({path, title, icon, onClick}) => {

    const Icon = icon

    return (
        <div className={'navLink'} onClick={() => onClick(path)}
             style={{backgroundColor: window.location.pathname === path ? 'rgba(117,117,117,0.2)' : 'rgba(117,117,117,0.0)'}}>
            <Icon color={window.location.pathname === path ? 'tertiary' : ''} className={'navLinkIcon'}/>
            <Typography color={window.location.pathname === path ? 'tertiary' : ''}>{title}</Typography>
        </div>
    )
}

const Layout = (props) => {
    const [open, setOpen] = React.useState(false);
    const [FdlDialogOpen, setFdlDialogOpen] = React.useState(false);
    const [weekCompilationState, setWeekCompilationState] = React.useState(false);
    const [errorsList, setErrorsList] = React.useState(List([]));
    const [auxData, setAuxData] = React.useState(Map({}));

    let location = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch()


    useEffect(() => {
        console.info('Boot get data')
        //On Boot
        // props.onPropsDateChangeHandler(new Date().toLocaleString());
        props.get_work_data(new Date(), 7);
        props.get_machines_list();
        // props.UpdateCommPers()
        props.get_userDataComm();
        props.get_statistics();
        props.get_worked_commissions();
        props.getMngData()
        props.getCommissionCodes()
        props.getUserFdl()
        props.getWorkdataAggregation()
        props.get_event_worktime()
        props.get_countries()
        props.get_regions()
        props.get_themes()
    }, []);

    const changePage = (destination) => {
        props.history.push(destination)
        setOpen(false);
    };

    const handleDrawerShow = () => {
        setOpen(!open);
    };

    const handleFdlDialogOpen = () => {
        setFdlDialogOpen(true);
    };

    const handleFdlDialogClose = () => {
        setFdlDialogOpen(false);
    };


    const handleWeekCompilationOpen = () => {
        setWeekCompilationState(true);
    };

    const handleWeekCompilationClose = () => {
        setWeekCompilationState(false);
    };

    const handleClosePopUpErrors = () => {
        setErrorsList(List([]));
        setFdlDialogOpen(false);
    }

    const confirmData = (days, force) => {
        return new Promise((resolve, reject) => {
            dispatch(lockDays(days, force)).then(() => {
                props.get_work_data(props.date.getIn([7, 'day']), 7)
                setErrorsList(List([]));
                setFdlDialogOpen(false);
                resolve()
            })
                .catch(({
                            data,
                            days,
                        }) => {
                    if (!data) {
                        return reject()
                    }
                    setErrorsList(fromJS(data))
                    setAuxData(fromJS({
                        type: 2,
                        days,
                    }))
                })
        })
    }

    const printFdl = (days, commissions, fdl, fdlDescription, generateFdl, generatePurchase, expAddData = {}, force = false, lockData) => {
        return new Promise((resolve, reject) => {
            props.generateFdl(days, commissions, fdl, fdlDescription, generateFdl, generatePurchase, expAddData, force, lockData)
                .then(res => {
                    console.debug(res.data["file"].length)
                    if (res.data["file"].length === 0) {
                        dispatch({
                            type: ENQUEUE_SNACKBAR,
                            notification: {
                                message: `Nessun dato trovato`,
                                key: new Date().getTime(),
                                options: {
                                    variant: 'warning',
                                    autoHideDuration: 3000,
                                },
                            },
                        })
                        resolve()
                        return
                    }
                    for (let file_name in res.data["file"]) {
                        props.downloadFdl(res.data["file"][file_name])
                            .then(res => {
                                let headerLine
                                let startFileNameIndex
                                let filename
                                headerLine = res.config.url
                                startFileNameIndex = headerLine.indexOf('=') + 1
                                filename = headerLine.substring(startFileNameIndex)
                                const url = window.URL.createObjectURL(new Blob([res.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', filename + '.pdf');
                                document.body.appendChild(link);
                                link.click();
                                setErrorsList(List([]));
                                setFdlDialogOpen(false);
                                resolve(true)
                            })
                            .catch(err => {
                                reject()
                            })
                    }
                    props.get_work_data(props.date.getIn([7, 'day']), 7)
                })
                .catch(({
                            data,
                            days,
                            commissions,
                            fdl,
                            fdlDescription,
                            generateFdl,
                            generatePurchase,
                            expAddData,
                            lockData
                        }) => {
                    if (!data) {
                        return reject()
                    }
                    setErrorsList(data)
                    setAuxData(fromJS({
                        type: 1,
                        days,
                        commissions,
                        fdl,
                        fdlDescription,
                        generateFdl,
                        generatePurchase,
                        expAddData,
                        lockData
                    }))
                })
        })
    }

    const printClean = (type) => {
        return new Promise((resolve, reject) => {
            props.generateCleanPdf(type)
                .then(res => {
                    let file_name = res.data["file"]
                    props.downloadFdl(file_name)
                        .then(res => {
                            let headerLine
                            let startFileNameIndex
                            let filename
                            headerLine = res.config.url
                            startFileNameIndex = headerLine.indexOf('=') + 1
                            filename = headerLine.substring(startFileNameIndex)
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename + '.pdf');
                            document.body.appendChild(link);
                            link.click();
                            setFdlDialogOpen(false);
                            resolve(true)
                        })
                        .catch(err => {
                            reject()
                        })
                })
                .catch(() => {
                    reject()
                })
        })
    }


    let commPage = null
    if (location.pathname === '/') {
        commPage = (
            <React.Fragment>
                {!props.media.littleScreen &&
                    <React.Fragment>
                        <div className="generate_fdl">
                            {/*<Button variant="outlined" color={'white'} onClick={handleWeekCompilationOpen}>*/}
                            {/*    Compilazione settimanale*/}
                            {/*</Button>*/}
                        </div>
                        <div className="generate_fdl">
                            <Button variant="outlined" color={'white'} onClick={handleFdlDialogOpen}>
                                genera FDL
                            </Button>
                        </div>
                    </React.Fragment>
                }
                <FdlDialog
                    open={FdlDialogOpen}
                    handleClose={handleFdlDialogClose}
                    printFdl={printFdl}
                    confirmData={confirmData}
                    printClean={printClean}
                    workedCommissions={props.eventWorktime}
                    get_event_worktime={props.get_event_worktime}
                />
                <WeekCompilationPopUp open={weekCompilationState} handleClose={handleWeekCompilationClose}/>
                <div className="divDatePicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns} utils={DateFnsUtils} className="RootTimePicker">
                        <DatePickerWeek
                            OnChangeHandler={(date) => props.get_work_data(date, 7)}/>
                    </LocalizationProvider>
                </div>
            </React.Fragment>
        )
    }

    console.debug(errorsList)
    return (
        <div className={'layout'}>
            <AppBar position='fixed' className={'appBar'} color={"primary"}
                    style={{backgroundColor: theme.palette.primary.main}}>
                <Toolbar>
                    <div className={'toolBar'}>
                        <div className={'toolBarLeft'}>
                            <IconButton
                                color="inherit"
                                onClick={handleDrawerShow}
                                edge="start"
                                className={'menuBtn'}> {open ? <ChevronLeftIcon/> : <MenuIcon/>}
                            </IconButton>

                        </div>
                        <div className={'toolBarCenter'}>

                        </div>
                        <div className={'toolBarRight'}>
                            {commPage}
                            <div className="avatar">
                                <UserPopUp
                                    user={props.user}
                                    logout={props.logout}
                                />
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant={props.media.desktop || props.media.largeDesktop ? "permanent" : "temporary"}
                className={open ? 'drawer drawerOpen' : 'drawer drawerClose'}
                // classes={{
                //     paper: open ? 'drawer drawerOpen' : 'drawer drawerClose',
                // }}
                open={open}
                transitionDuration={{enter: 300, exit: 300}}
            >
                <MenuItem path={'/'} title={'Home'} icon={QueryBuilderIcon} onClick={changePage}/>
                <MenuItem path={'/calendar_view'} title={'Vista calendario'} icon={CalendarTodayRoundedIcon}
                          onClick={changePage}/>
                <MenuItem path={'/commpers'} title={'Commesse personali'} icon={RecentActorsIcon} onClick={changePage}/>
                <MenuItem path={'/comm'} title={'Lista commesse'} icon={ListAltIcon} onClick={changePage}/>
                <MenuItem path={'/fdl'} title={'Fdl'} icon={FldIcon} onClick={changePage}/>
                {
                    props.user.getIn(['role', 'fdl_management']) &&
                    <>
                        {/*<MenuItem path={'/WdFdlExport'} title={'Export per FDL'} icon={FileOpenRoundedIcon}*/}
                        {/*          onClick={changePage}/>*/}
                        <MenuItem path={'/WdExport'} title={'Export ore dipendenti'} icon={FileOpenRoundedIcon}
                                  onClick={changePage}/>
                        <MenuItem path={'/workdataCompilation'} title={'workdataCompilation'}
                                  icon={CalendarMonthRoundedIcon} onClick={changePage}/>
                        <MenuItem path={'/rawData'} title={'Raw data'}
                                  icon={RawOnRoundedIcon} onClick={changePage}/>
                    </>
                }
                {
                    props.user.getIn(['userData', 'config', 'beta']) &&
                    <>
                        <MenuItem path={'/statistics'} title={'Statistiche'} icon={AssessmentRoundedIcon}
                                  onClick={changePage}/>
                    </>
                }
                {
                    props.user.getIn(['userData', 'config', 'alpha']) &&
                    <>
                    </>
                }
                {
                    props.user.getIn(['userData', 'config', 'themeManagement']) &&
                    <>
                        <MenuItem path={'/themes'} title={'Tema'} icon={BrushRoundedIcon} onClick={changePage}/>
                        <MenuItem path={'/themesManagement'} title={'Gestici temi'} icon={BrushRoundedIcon}
                                  onClick={changePage}/>
                    </>
                }
                <MenuItem path={'/settings'} title={'Impostazioni'} icon={SettingsIcon} onClick={changePage}/>
            </Drawer>
            <main className={'layoutContent'}>
                {props.children}
            </main>
            <FeaturePopUp/>
            <Dialog
                open={errorsList.size > 0}
                // onClose={this.props.handleClose}
                className={'fdlErrorsDialog'}
                maxWidth={"xl"}
            >
                <DialogTitle id="max-width-dialog-title">Errori compilazione FDL</DialogTitle>
                <Divider variant={"middle"}/>
                <DialogContent className={'content'}>
                    {errorsList.map(e => {
                        let errorIcon
                        switch (e.get('level')) {
                            case 20:
                                errorIcon = <WarningRoundedIcon style={{color: '#fb8c00'}} className={'icon'}/>
                                break;
                            case 30:
                                errorIcon = <ErrorRoundedIcon style={{color: '#e53935'}} className={'icon'}/>
                                break;
                            case 40:
                                errorIcon = <NewReleasesRoundedIcon style={{color: '#e53935'}} className={'icon'}/>
                                break;
                            default:
                                errorIcon = <InfoRoundedIcon style={{color: '#1e88e5'}} className={'icon'}/>
                                break;
                        }
                        return (
                            <>
                                <div className={'row'}>
                                    {errorIcon}
                                    <Typography className={'date'}>{e.get('day')} - </Typography>
                                    <div>
                                        <Typography className={'ErrorInfo'}>{e.get('info')}</Typography>
                                        <Typography className={'details'}>
                                            {e.get('commission') ? `${e.get('commission')}` : null}
                                            {e.get('commission') && e.get('value') ? ' - ' : null}
                                            {e.get('value') ? e.get('value') : null}
                                        </Typography>
                                    </div>
                                </div>
                                <Divider variant={"fullWidth"}/>
                            </>
                        )
                    })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePopUpErrors} color="secondary">
                        Annulla
                    </Button>
                    <Button
                        disabled={errorsList?.some(e => e.get('level') === 40)}
                        onClick={() => {
                            const {
                                days,
                                commissions,
                                fdl,
                                fdlDescription,
                                generateFdl,
                                generatePurchase,
                                expAddData,
                                lockData
                            } = auxData.toJS()
                            if (auxData.get('type') === 1) {
                                printFdl(days, commissions, fdl, fdlDescription, generateFdl, generatePurchase, expAddData, true, lockData)
                            } else {
                                confirmData(days, true)
                            }
                        }} variant={"contained"} color="secondary">
                        {auxData.get('type') === 1 ? "Forza creazione PDF" : "Forza conferma dati"}

                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        date: state.data.day,
        user: state.auth.user,
        options: state.auth.options,
        eventWorktime: state.data.eventWorktime,
        beta: state.auth.user.getIn(["userData", "config", 'beta'], false),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        get_work_data: (day, bef_days) => dispatch(getWorkData(day, bef_days)),
        get_machines_list: () => dispatch(get_machines_list()),
        logout: () => dispatch(logoutUser()),
        get_userDataComm: () => dispatch(get_userDataComm()),
        get_statistics: () => dispatch(get_statistics()),
        get_worked_commissions: () => dispatch(get_worked_commissions()),
        getMngData: () => dispatch(get_mng_data()),
        get_event_worktime: () => dispatch(getEventWorktime()),
        generateFdl: (...args) => dispatch(generateFdl(...args)),
        generateCleanPdf: (...args) => dispatch(generateCleanPdf(...args)),
        downloadFdl: (...args) => dispatch(downloadFdl(...args)),
        getCommissionCodes: (...args) => dispatch(getCommissionCodes(...args)),
        getUserFdl: (...args) => dispatch(getUserFdl(...args)),
        getWorkdataAggregation: (...args) => dispatch(getWorkdataAggregation(...args)),
        get_countries: (...args) => dispatch(get_countries(...args)),
        get_regions: (...args) => dispatch(get_regions(...args)),
        get_themes: (...args) => dispatch(get_themes(...args)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMedia(Layout)));
