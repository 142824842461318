import React, {FC} from 'react';
import {Button, DialogContent, Paper, Tooltip, Dialog, DialogActions} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Typography from "@mui/material/Typography";
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';

type tError = {
    code: string
    commission: string
    day: string
    info: string
    level: number
    value: string
}

interface props {
    errors: tError[]
    confirmText: string
    onConfirm : () => void
    onCancel : () => void

}

const ErrorsDialog:FC<props> = ({errors, confirmText, onConfirm, onCancel, }) => {

    return         <Dialog
                open={errors.length > 0}
                // onClose={this.props.handleClose}
                className={'fdlErrorsDialog'}
                maxWidth={"xl"}
            >
                <DialogTitle id="max-width-dialog-title">Errori compilazione FDL</DialogTitle>
                <Divider variant={"middle"}/>
                <DialogContent className={'content'}>
                    {errors.map(e => {
                        let errorIcon
                        switch (e.level) {
                            case 20:
                                errorIcon = <WarningRoundedIcon style={{color: '#fb8c00'}} className={'icon'}/>
                                break;
                            case 30:
                                errorIcon = <ErrorRoundedIcon style={{color: '#e53935'}} className={'icon'}/>
                                break;
                            case 40:
                                errorIcon = <NewReleasesRoundedIcon style={{color: '#e53935'}} className={'icon'}/>
                                break;
                            default:
                                errorIcon = <InfoRoundedIcon style={{color: '#1e88e5'}} className={'icon'}/>
                                break;
                        }
                        return (
                            <>
                                <div className={'row'}>
                                    {errorIcon}
                                    <Typography className={'date'}>{e.day} - </Typography>
                                    <div>
                                        <Typography className={'ErrorInfo'}>{e.info}</Typography>
                                        <Typography className={'details'}>
                                            {e.commission ? `${e.commission}` : null}
                                            {e.commission && e.value ? ' - ' : null}
                                            {e.value ? e.value : null}
                                        </Typography>
                                    </div>
                                </div>
                                <Divider variant={"fullWidth"}/>
                            </>
                        )
                    })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>onCancel()} color="secondary">
                        Annulla
                    </Button>
                    <Button
                        disabled={errors?.some(e => e.level === 40)}
                        onClick={() => onConfirm()}
                        variant={"contained"}
                        color="secondary"
                    >
                        {confirmText}
                    </Button>
                </DialogActions>
        </Dialog>
}

export default ErrorsDialog