import React from "react";
import './Day.css'
import {ButtonBase, Paper, Tooltip, Typography} from "@mui/material";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import IconButton from "@mui/material/IconButton";
import {useTheme} from '@mui/material/styles';
import moment from "moment";
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';

const Day = (props) => {
    const theme = useTheme();
    const day = moment(props.day.get('day'))
    const today = moment().format('DD-MM-YYYY') === day.format('DD-MM-YYYY')

    let cssClass
    let style = {}

    if (today || props.forceToday) {
        cssClass = ' actDay '
        style= {backgroundColor: theme.palette.primary.main}
    }
    if (props.selectedDay === day.format('DD-MM-YYYY') && !props.day.get('ovl_timeslots')) {
        cssClass = cssClass + ' selectedDay'
        style = {...style, outlineColor:theme.palette.tertiary.main}
    } else if (props.selectedDay === day.format('DD-MM-YYYY') && props.day.get('ovl_timeslots')) {
        cssClass = cssClass + ' selectedDayError'
        style = {...style, outlineColor:'e53935'}
    }

    const actDayName = day.format('ddd')

    const weekEndDay = day.format('e') > 4

    return (
        //I bottoni dentro a un altro bottone vengono segnalati come un errore nella console,
        //ma devono rimanere così in modo da essere allineati con il bottone
        <ButtonBase onClick={() => props.onClick(day.format('DD-MM-YYYY'))} className={'day'}
                    style={{borderWidth: props.day.get('ovl_timeslots') ? 2 : 0}}>
            <IconButton className={props.first ? 'weekBtn backButton' : 'weekBtn hide'}
                         onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                props.prevWeekClickHandle()
            }}>
                <ArrowBackRoundedIcon/>
            </IconButton>
            <IconButton className={props.last ? 'weekBtn nextButton' : 'weekBtn hide'} onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                props.nextWeekClickHandle()
            }}>
                <ArrowForwardRoundedIcon/>
            </IconButton>
            <Paper className={cssClass + ' dayContent'} style={style} transparent={today}>
                <div className={'hover'}>
                    <div className={'dayNumber'}>
                        <Typography variant={"h3"} color={weekEndDay && 'tertiary'}>{day.format('D')}</Typography>
                        <Typography className={'actDayName'} color={weekEndDay && 'tertiary'}>{actDayName}</Typography>
                    </div>
                    <Typography style={{fontSize:15}} color={weekEndDay && 'tertiary'}>Sett. {day.format('W')}</Typography>
                    <Typography color={weekEndDay && 'tertiary'}>{day.format('MMMM')} {day.format('YYYY')}</Typography>
                    <div className={'infoDay'}>
                        <Typography style={{fontSize: '0.8rem'}} color={weekEndDay && 'tertiary'}>
                            Ore viaggio: {Math.round(props.totTravel * 100) / 100}h
                        </Typography>
                        <Typography style={{fontSize: '0.8rem'}} color={weekEndDay && 'tertiary'}>
                            Ore lavoro: {Math.round(props.totDay * 100) / 100}h
                        </Typography>
                    </div>
                </div>
                {
                    props.confirmed &&
                    <Tooltip title={"Dati confermati all'amministrazione"}>

                    <VerifiedUserRoundedIcon
                        style={{
                            position:'absolute',
                            bottom: '5px',
                            right: '5px'
                        }}
                        color={"success"}
                    />
                    </Tooltip>
                }
                {
                    props.locked &&
                    <Tooltip title={"Dati bloccati dall'amministrazione"}>

                    <BlockRoundedIcon
                        style={{
                            position:'absolute',
                            bottom: '5px',
                            right: '5px'
                        }}
                        color={"error"}
                    />
                    </Tooltip>
                }
            </Paper>
        </ButtonBase>
    )
}

export default Day;