import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import React, {useState} from "react";
import moment from "moment";
import style from "../WdExport.module.scss";
import {Button, Card, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import MonthPicker from "../../Library/MonthPicker/MonthPicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {downloadWdExport} from "../../../store/actions/exportData";
import ConfirmPopUp from "../../Library/ConfirmPopUp/ConfirmPopUp";

const EmployeeExport = () => {

    const dispatch = useDispatch()


    const [date, setDate] = useState(moment().month(moment().month() - 1).startOf('month'))
    const [definitiveExport, setDefinitiveExport] = useState(false)
    const [confirmExport, setConfirmExport] = useState(false)


    return <>
        <Card className={style.card}>
            <Typography variant={"h6"}>Export ore dipendenti</Typography>
            <Divider flexItem/>
            <MonthPicker
                value={date}
                onChange={(n) => {
                    setDefinitiveExport(false)
                    setDate(n)
                }}
            />
            {/*<FormControlLabel*/}
            {/*    control={<Checkbox checked={definitiveExport}/>}*/}
            {/*    label="Export definitivo"*/}
            {/*    disabled={date.startOf('month') >= moment().startOf('month')}*/}
            {/*    onChange={() => setDefinitiveExport(old => !old)}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*    onClick={() => dispatch(getFdlDataExport(integration, date.month() + 1, date.year()))}*/}
            {/*>*/}
            {/*    Visualizza dati*/}
            {/*</Button>*/}
            <Button
                onClick={() => {
                    if (definitiveExport)
                        setConfirmExport(true)
                    else
                        dispatch(downloadWdExport(date.month() + 1, date.year(), false))

                }}
                variant={'contained'}
                fullWidth
                style={{alignSelf:'flexEnd', margin:'auto auto 0 auto'}}
            >
                Export
            </Button>
        </Card>

        <ConfirmPopUp
            open={confirmExport}
            handleAccept={() => {
                dispatch(downloadWdExport(date.month() + 1, date.year(), true))
                setConfirmExport(false)
            }}
            handleDecline={() => {
                setDefinitiveExport(false)
                setConfirmExport(false)
            }}
            title={`Generazione export definitivo`}
            description={`Attenzione generando un export definitivo gli utenti non potranno più modificare le ore inserite (appartenenti al mese selezionato)!!!`}
            ko={'Annulla'}
            ok={'Genera'}
        />

        <ConfirmPopUp
            open={confirmExport}
            handleAccept={() => {
                dispatch(downloadWdExport(date.month() + 1, date.year(), true))
                setConfirmExport(false)
            }}
            handleDecline={() => {
                setDefinitiveExport(false)
                setConfirmExport(false)
            }}
            title={`Blocco dati`}
            description={`Attenzione confermando verranno bloccati tutti i dati inseriti dai tecnici nella settimana selezionata!`}
            ko={'Annulla'}
            ok={'Conferma'}
        />
    </>

}

export default EmployeeExport;