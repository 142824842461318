import React, {useState} from 'react'
import {Button, Paper, Tooltip} from "@mui/material";
import style from './WorkToolbar.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {getWorkData, lockDay, onWDAdd} from "../../../store/actions/WorkData";
import Typography from "@mui/material/Typography";
import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import ErrorsDialog from "../../Library/ErrorsDialog/ErrorsDialog";

const WorkToolbar = ({idxDay, day, lockedDay, confirmedDay, editedDay, lastDay, paidLeaveInserted}) => {
    const dispatch = useDispatch()

    const date = useSelector(state => state.data.day)

    const [errorList, setErrorList] = useState([])

    const _lockDay = (force) => {
        dispatch(lockDay(day, force))
            .then((res) => {
                dispatch(getWorkData(date.getIn([7, 'day']), 7))
                setErrorList([])
            })
            .catch(err => {
                if (err)
                    setErrorList(err.data)
            })
    }

    return <Paper className={style.workToolbar} style={{
        backgroundColor: lastDay ? '#c62828' : '#00000000',
        backgroundImage: lastDay ? 'unset' : ''
    }}>
        <div className={style.left}>
            <Tooltip title={'Segna giorno come ferie'}>
                <Button
                    variant={"contained"}
                    className={style.button}
                    disabled={paidLeaveInserted || lockedDay}
                    onClick={() => dispatch(onWDAdd(idxDay, day, 0, 2))}
                >
                    Ferie
                </Button>
            </Tooltip>
            <Tooltip title={'Aggiungi permesso'}>
                <Button
                    variant={"contained"}
                    className={style.button}
                    disabled={paidLeaveInserted || lockedDay}
                    onClick={() => dispatch(onWDAdd(idxDay, day, 0, 1))}
                >
                    Permesso
                </Button>
            </Tooltip>
            <Tooltip title={'Segna come malattia'}>
                <Button
                    variant={"contained"}
                    className={style.button}
                    disabled={paidLeaveInserted || lockedDay}
                    onClick={() => dispatch(onWDAdd(idxDay, day, 0, 3))}
                >
                    Malattia
                </Button>
            </Tooltip>
            <Tooltip title={`${editedDay && 'Giorno modificato, riconferma - '} Rendi disponibile i dati all'amministrazione`}>
                <Button
                    variant={"contained"}
                    className={`${style.button} ${editedDay && style.blink}`}
                    color={editedDay? 'error': 'primary'}
                    disabled={lockedDay || confirmedDay}
                    style={{marginLeft: 25}}
                    onClick={() => _lockDay(false)}
                >
                    Conferma giorno
                </Button>
            </Tooltip>

        </div>
        {lastDay &&
            <div style={{display: 'flex'}}>
                <NewReleasesRoundedIcon style={{marginRight: '10px'}}/>
                <Typography>Ultimo giorno del mese, caricare tutte le ore</Typography>
            </div>
        }
        <div className={style.right}>
            <Button
                variant={'contained'}
                onClick={() => dispatch(onWDAdd(idxDay, day))}
                disabled={lockedDay}
            >
                Aggiungi commessa
            </Button>
        </div>
        <ErrorsDialog
        errors={errorList}
        confirmText={'Forza conferma dati'}
        onCancel={()=>setErrorList([])}
        onConfirm={()=>_lockDay(true)}
        />

    </Paper>
}

export default WorkToolbar;