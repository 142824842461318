import React, {useEffect, useState} from 'react'
import style from './ClientDialog.module.scss'
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import {fromJS, List, Map} from 'immutable'
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ConfirmPopUp from "../../Library/ConfirmPopUp/ConfirmPopUp";
import {Tab, Tabs, TextField, Typography} from "@mui/material";
import moment from "moment/moment";
import {MenuItem} from "react-contextmenu";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DateRangePicker} from "@mui/x-date-pickers-pro/DateRangePicker";
import Box from "@mui/material/Box";
import {MoneyKmTextFieldFormat} from "../../Library/Utils/Utils";
import RateTimeCase from "./RateTimeCase/RateTimeCase";
import {useDispatch, useSelector} from "react-redux";
import {putClient} from "../../../store/actions/MngData";
import 'dayjs/locale/it';
import dayjs from "dayjs";
import TabPanel from "../../Library/TabPanel/TabPanel";
import AppBar from "@mui/material/AppBar";

const newTimeCase = {
    regions: [],
    trip_type: [1],
    type: 2,
    all_inclusive: true,
    ordinary_rate: 500,
    extraordinary_rate: 600,
    ordinary_hour: '10:00:00',
    avg_hour_per_day: '10:00:00',
    validity_days: [1, 2, 3, 4, 5, 6],
    holiday_supplement_type: 1,
    holiday_supplement: 10,
    start_day_slot: '7:00:00',
    end_day_slot: '21:00:00',
    night_supplement_type: 1,
    night_supplement: 10,
    rate_plan_id: 2,
    validity: 1,
    extra_business_agreements: '',
}

const getIndex = (list, id) => {
    let index = list.findIndex(i => i.get('tempId') == id)
    if (index === -1) {
        index = list.findIndex(i => i.get('id') == id)
    }
    return index
}


const ClientDialog = ({client, onClose}) => {
    const dispatch = useDispatch()

    const [actClient, setActClient] = useState(Map({}))
    const [popUpDeleteRatePlan, setPopUpDeleteRatePlan] = useState(false)
    const [popUpForceClose, setPopUpForceClose] = useState(false)
    const [actualRatePlan, setActualRatePlan] = useState(null)
    const [actTab, setActTab] = useState(0)

    const regions = useSelector(state => state.meta.regions)

    useEffect(() => {
        setActClient(client)
    }, [client])

    useEffect(() => {
        selectRatePlan()
    }, [actualRatePlan, actClient])

    const selectRatePlan = () => {
        if (!actClient.get('rate_plans')) {
            return
        }
        if (!actualRatePlan) {
            const date = dayjs()
            const temp = actClient.get('rate_plans')?.find(rt =>
                dayjs(rt?.get('valid_from'), 'DD/MM/YYYY') <= date &&
                date <= dayjs(rt?.get('valid_to'), 'DD/MM/YYYY')
            ) || actClient.get('rate_plans').first()

            setActualRatePlan(temp?.get('id'))
        }
    }

    const onCLoseDialog = () => {
        if (!client.equals(actClient)) {
            setPopUpForceClose(true)
        } else {
            onForceCLoseDialog()
        }
    }

    const onForceCLoseDialog = () => {
        setPopUpForceClose(false)
        setActualRatePlan(null)
        onClose()
    }

    const onSave = (close) => {
        dispatch(putClient(client.get('client_code'), actClient))
            .then(() => {
                setPopUpForceClose(false)
                if (close || client.get('client_code') !== actClient.get('client_code')) {
                    onClose()
                }
            })
    }

    const onRatePlanChange = (key, value) => {
        const _ratePlanId = getIndex(actClient.get('rate_plans'), actualRatePlan)
        setActClient(old => old.updateIn(['rate_plans', _ratePlanId, key], () => value))
    }

    const onAddRatePlan = () => {
        const tempId = new Date().getTime()
        setActClient(old => old.updateIn(['rate_plans'], rt => rt.push(Map({
            tempId: tempId,
            client_code: actClient.get('client_code'),
            valid_from: moment().format('DD/MM/YYYY'),
            valid_to: moment().add(1, 'y').format('DD/MM/YYYY'),
            rate_time_cases: List([])
        }))))
        setActualRatePlan(tempId)
    }

    const onDeleteRatePlan = () => {
        const _ratePlanIdx = getIndex(actClient.get('rate_plans'), actualRatePlan)
        setActClient(old => old.updateIn(['rate_plans'], rt => rt.delete(_ratePlanIdx)))
        setActualRatePlan(null)
    }

    const onAddRateTimeCase = () => {
        const _ratePlanId = getIndex(actClient.get('rate_plans'), actualRatePlan)
        let _newTimeCase = fromJS(newTimeCase)
        _newTimeCase = _newTimeCase.set('tempId', new Date().getTime())
        setActClient(old => old.updateIn(['rate_plans', _ratePlanId, 'rate_time_cases'], old => old.push(_newTimeCase)))
    }

    const onChangeRateTimeCase = (rateTimeId, key, value) => {
        const _ratePlanId = getIndex(actClient.get('rate_plans'), actualRatePlan)
        const _rateTimeCaseId = getIndex(actClient.getIn(['rate_plans', _ratePlanId, 'rate_time_cases']), rateTimeId)
        setActClient(old => old.updateIn(['rate_plans', _ratePlanId, 'rate_time_cases', _rateTimeCaseId, key], () => value))
    }

    const onDeleteRateTimeCase = (rateTimeId) => {
        const _ratePlanId = getIndex(actClient.get('rate_plans'), actualRatePlan)
        const _rateTimeCaseId = getIndex(actClient.getIn(['rate_plans', _ratePlanId, 'rate_time_cases']), rateTimeId)
        setActClient(old => old.deleteIn(['rate_plans', _ratePlanId, 'rate_time_cases', _rateTimeCaseId,]))
    }

    const onDuplicateRateTimeCase = (rateTimeId) => {
        const _ratePlanId = getIndex(actClient.get('rate_plans'), actualRatePlan)
        const _rateTimeCaseId = getIndex(actClient.getIn(['rate_plans', _ratePlanId, 'rate_time_cases']), rateTimeId)
        let _rateTimeCase = actClient.getIn(['rate_plans', _ratePlanId, 'rate_time_cases', _rateTimeCaseId])
        _rateTimeCase = _rateTimeCase.delete('id')
        _rateTimeCase = _rateTimeCase.set('tempId', new Date().getTime())

        setActClient(old => old.updateIn(['rate_plans', _ratePlanId, 'rate_time_cases',], old => old.push(_rateTimeCase)))
    }


    let ratePlan = Map({})
    let rateCases
    if (actClient.get('rate_plans')) {
        const _ratePlanIndex = getIndex(actClient.get('rate_plans'), actualRatePlan)
        ratePlan = actClient.getIn(['rate_plans', _ratePlanIndex], Map({}))
        if (ratePlan.get('rate_time_cases', List([])).size === 0) {
            rateCases = <Typography style={{marginLeft: 10}}>Nessuna casistica definita</Typography>
        } else {
            rateCases = ratePlan.get('rate_time_cases').map(rt => <RateTimeCase
                id={'rateTine'}
                key={rt.get('tempId') || rt.get('id')}
                rateTime={rt}
                regions={regions}
                onChange={onChangeRateTimeCase}
                onDelete={onDeleteRateTimeCase}
                onDuplicate={onDuplicateRateTimeCase}
            />)
        }
    }
    const ratePlanId = ratePlan.get("tempId") || ratePlan.get("id")

    return (<>
        <Dialog
            id={'client_dialog'}
            open={actClient.size}
            onClose={() => onCLoseDialog(false)}
            className={style.clientDialog}
            fullWidth
            maxWidth={"xl"}
        >
            {/*<DialogTitle*/}
            {/*    className={style.header}>Tariffario: {client.get('company_name')} ({client.get('client_code')})</DialogTitle>*/}
            <Divider/>
            <DialogContent className={style.content}>
                <AppBar position="static" color="default">
                    <Tabs value={actTab} onChange={(e, n) => setActTab(n)}>
                        <Tab label="Tariffario" value={0}/>
                        <Tab label="Configurazioni" value={1}/>
                    </Tabs>
                </AppBar>
                <TabPanel value={actTab} index={0}>
                    <div className={style.ratePlan}>

                        <div className={style.general}>
                            <div className={style.header}>
                                <TextField
                                    id="ratePlanList"
                                    select
                                    label="Tariffari"
                                    value={actualRatePlan}
                                    onChange={(event) => {
                                        setActualRatePlan(event.target.value)
                                    }}
                                    fullWidth
                                    className={style.ratePlanSelector}
                                >
                                    {actClient?.get('rate_plans')?.sortBy(rt => moment(rt.get('valid_from'), 'DD-MM-YYYY')).map((option) => {
                                        const _id = option.get("tempId") || option.get("id")
                                        return <MenuItem key={_id} value={_id}>
                                            {option.get('valid_from')} - {option.get('valid_to')}
                                        </MenuItem>
                                    })}
                                </TextField>
                            </div>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={'it'}
                                localeText={{start: 'Valido da', end: 'Fino'}}
                            >
                                <DateRangePicker
                                    style={{width: '100%', display: 'flex'}}
                                    id={`from_to`}
                                    key={`from_to_${ratePlanId}`}
                                    value={[dayjs(ratePlan?.get('valid_from'), 'DD/MM/YYYY'), dayjs(ratePlan?.get('valid_to'), 'DD/MM/YYYY')]}
                                    onChange={(newValue) => {
                                        onRatePlanChange('valid_from', newValue[0]?.format('DD/MM/YYYY'))
                                        onRatePlanChange('valid_to', newValue[1]?.format('DD/MM/YYYY'))
                                    }}
                                    renderInput={(startProps, endProps) => (
                                        <div className={style.rangePicker}>
                                            <TextField {...startProps} />
                                            <Typography style={{marginBottom:5}}><strong>--</strong></Typography>
                                            <TextField {...endProps} />
                                        </div>
                                    )}
                                />
                            </LocalizationProvider>
                            <TextField
                                id={`km_refund`}
                                key={`km_refund_${ratePlanId}`}
                                label={'Rimborso chilometrico'}
                                size={"small"}
                                margin={"dense"}
                                InputProps={{inputComponent: MoneyKmTextFieldFormat}}
                                value={ratePlan.get('km_refund')}
                                onChange={event => onRatePlanChange('km_refund', event.target.value)}
                                onBlur={event => {
                                    if (!event.target.value)
                                        onRatePlanChange('km_refund', 0)
                                }}
                            />
                            <TextField
                                id={`extra_business_agreements`}
                                key={`extra_business_agreements_${ratePlanId}`}
                                label={`Accordi aggiuntivi`}
                                size={"small"}
                                margin={"dense"}
                                value={ratePlan.get('extra_business_agreements')}
                                onChange={event => onRatePlanChange('extra_business_agreements', event.target.value)}
                                minRows={3}
                                maxRows={7}
                                multiline={true}
                            />
                            <div className={style.files}>
                                <TextField
                                    id={`offer`}
                                    key={`offer_${ratePlanId}`}
                                    label={'Offerta'}
                                    size={"small"}
                                    margin={"dense"}
                                    value={ratePlan.get('offer')}
                                    onChange={event => onRatePlanChange('offer', event.target.value)}
                                    className={style.fileInput}
                                    style={{marginRight:10}}
                                />
                                <TextField
                                    id={`offer_file`}
                                    key={`offer_file_${ratePlanId}`}
                                    label={'Documento di offerta'}
                                    size={"small"}
                                    margin={"dense"}
                                    value={ratePlan.get('offer')}
                                    onChange={event => onRatePlanChange('offer', event.target.value)}
                                    className={style.fileInput}
                                />
                            </div>
                            <div className={style.files}>
                                <TextField
                                    id={`client_confirmation`}
                                    key={`client_confirmation_${ratePlanId}`}
                                    label={'Conferma offerta'}
                                    size={"small"}
                                    margin={"dense"}
                                    value={ratePlan.get('client_confirmation')}
                                    onChange={event => onRatePlanChange('client_confirmation', event.target.value)}
                                    className={style.fileInput}
                                    style={{marginRight:10}}
                                />
                                <TextField
                                    id={`client_confirmation_file`}
                                    key={`client_confirmation_${ratePlanId}`}
                                    label={'Documento conferma cliente'}
                                    size={"small"}
                                    margin={"dense"}
                                    value={ratePlan.get('client_confirmation')}
                                    onChange={event => onRatePlanChange('client_confirmation', event.target.value)}
                                    className={style.fileInput}
                                />
                            </div>
                        </div>
                        <div className={style.rate_time_case}>
                            {rateCases}
                            <Button
                                variant={"text"}
                                size={"small"}
                                color={'white'}
                                style={{backgroundColor: '#4e4e4e', margin: '0 0 0 10px', padding: '1px 5px'}}
                                onClick={onAddRateTimeCase}
                            >
                                Aggiungi opzione
                            </Button>
                        </div>
                    </div>
                </TabPanel>


                <TabPanel value={actTab} index={1}>
                    <div className={style.settings}>
                        <TextField
                            id={'clientCode'}
                            label={"codice client"}
                            className={style.TextField}
                            value={actClient.get('client_code')}
                            onChange={(e) => {
                                const _value = e.target.value
                                if (_value.length <= 4) {
                                    setActClient(old => old.updateIn(['client_code'], () => _value))
                                }
                            }}
                        />
                        <TextField
                            id={'clientName'}
                            label={'nome cliente'}
                            className={style.TextField}
                            value={actClient.get('company_name')}
                            onChange={(e) => setActClient(old => old.updateIn(['company_name'], () => e.target.value))}

                        />
                    </div>
                </TabPanel>

            </DialogContent>
            <DialogActions className={style.action}>
                {actTab === 0 &&
                    <div className={style.leftActions}>
                        <Button
                            variant={"contained"}
                            // className={'action_buttonstyle.}
                            onClick={onAddRatePlan}
                            className={style.action_button}
                            color="secondary"
                            startIcon={<AddCircleRoundedIcon/>}
                        > Aggiungi tariffario
                        </Button>
                        <Button
                            variant={"contained"}
                            // className={'action_buttonstyle.}
                            onClick={onDeleteRatePlan}
                            className={style.action_button}
                            startIcon={<DeleteRoundedIcon/>}
                            color={'error'}
                        > Elimina tariffario
                        </Button>
                    </div>
                }
                <div className={style.rightActions}>
                    <Button
                        variant={"outlined"}
                        color="secondary"
                        className={style.action_button}
                        onClick={onCLoseDialog}
                    > Annulla
                    </Button>
                    <Button
                        variant={"contained"}
                        color="secondary"
                        className={style.action_button}
                        onClick={() => onSave(true)}
                    > Salva e chiudi
                    </Button>
                    <Button
                        variant={"contained"}
                        color="secondary"
                        className={style.action_button}
                        onClick={() => onSave(false)}
                    > Salva
                    </Button>
                </div>
            </DialogActions>
        </Dialog>

        <ConfirmPopUp
            open={popUpDeleteRatePlan}
            handleAccept={() => setActClient(old => old.updateIn(['rate+plans'], rt => rt.delete(actualRatePlan)))}
            handleDecline={() => setPopUpDeleteRatePlan(false)}
            title={`Eliminazione tariffario`}
            description={`Sei siscuro di voler eliminare questo tariffario?`}
            ko={'Annulla'}
            ok={'Conferma'}
        />
        <ConfirmPopUp
            open={popUpForceClose}
            handleAccept={() => onSave(true)}
            handleDecline={() => onForceCLoseDialog()}
            handleCancel={() => setPopUpForceClose(false)}
            title={`Chiudere senza salvare`}
            description={`Sei siscuro di voler chiudere senza salvare?`}
            ko={'Chiudi senza salvare'}
            ok={'Salva'}
            cancel={'Annulla'}
        />
    </>)
}

export default ClientDialog