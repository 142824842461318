import React, {useEffect, useRef, useState} from 'react'
import './FdlDialog.scss'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from "moment";
import MomentLocaleUtils from 'react-day-picker/moment';
import {
    ButtonGroup,
    ClickAwayListener,
    Grow,
    IconButton,
    MenuList,
    Paper,
    Popper,
    Skeleton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {List, Map, Set} from "immutable";
import {getDates} from "../../components/Library/Utils/Utils";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from "@mui/material/MenuItem";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import apiV2 from "../../apiV2";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useTheme} from "@mui/material/styles";
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import {lockDays} from "../../store/actions/WorkData";
import {useDispatch} from "react-redux";

function getWeekDays(day) {
    let startWeek = moment(day).startOf('week')
    if (moment(day).month() !== startWeek.month() || moment(day).year() !== startWeek.year()) {
        startWeek = moment(day).startOf('month')
    }
    let endWeek = moment(day).endOf('week')
    if (endWeek.month() !== startWeek.month() || endWeek.year() !== startWeek.year()) {
        endWeek = moment(day).endOf('month')
    }
    return {from: startWeek.toDate(), to: endWeek.toDate()};
}

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const stringDateEquality = (d1, d2) => new Date(d1).toDateString() === new Date(d2).toDateString()


const FdlDialog = (props) => {

    const theme = useTheme()
    const dispatch = useDispatch()

    const cleanAnchorRef = useRef();
    const dataAnchorRef = useRef();

    const [datesSelectionType, setDatesSelectionType] = useState(0)
    const [rangeDates, setRangeDates] = useState({from: undefined, to: undefined})
    const [singleDates, setSingleDates] = useState(Set([]))
    const [selectedComm, setSelectedComm] = useState(Set([]))
    const [expAddData, setExpAddData] = useState(Map({
        'currency': '',
        'exc_e_vl': List([
            Map({'euro': 0.0, 'vl': 0.0}),
            Map({'euro': 0.0, 'vl': 0.0}),
            Map({'euro': 0.0, 'vl': 0.0})]),
        'exc_vl_e': List([
            Map({'euro': 0.0, 'vl': 0.0}),
            Map({'euro': 0.0, 'vl': 0.0}),
            Map({'euro': 0.0, 'vl': 0.0})]),
        'exc': List([
            Map({'currency': '', 'tot': 0.0}),
            Map({'currency': '', 'tot': 0.0}),
            Map({'currency': '', 'tot': 0.0})])
    }))
    const [generalDescription, setGeneralDescription] = useState('')
    const [printOptionsState, setPrintOptionsState] = useState(0)
    const [printOptions, setPrintOptions] = useState({commissionList: [], fdlList: []})


    const [dataAnimation, setDataAnimation] = useState(false)
    const [dataConfirmationAnimation, setDataConfirmationAnimation] = useState(false)
    const [subDataAnimation, setSubDataAnimation] = useState(false)
    const [subDataOptMenu, setSubDataOptMenu] = useState(false)
    const [actTabAddData, setActTabAddData] = useState('ore')

    useEffect(() => {
        if (!props.open) {
            setDatesSelectionType(0)
            setSelectedComm(Set([]))
            setRangeDates({from: undefined, to: undefined})
            setSingleDates(Set([]))
            setDataAnimation(false)
            setDataConfirmationAnimation(false)
            setSubDataAnimation(false)
        }
    }, [props.open])

    useEffect(() => {
        setPrintOptionsState(1)
        setPrintOptions({commissionList: [], fdlList: []})
        let _days = []
        if (datesSelectionType === 2) {
            _days = singleDates.toJS()
        } else {
            _days = getDates(rangeDates.from, rangeDates.to)
        }
        apiV2.post('printOptions', {days: _days})
            .then(response => {
                setPrintOptions(response.data)
                setSelectedComm(Set(response.data.commissionList))
                setPrintOptionsState(200)
            })
            .catch(err => {
                setPrintOptionsState(400)
                console.error(err)
            })
    }, [rangeDates, singleDates, datesSelectionType])

    const weekDayChangeHandle = (date, {selected}) => {
        switch (datesSelectionType) {
            case 0:
                setRangeDates(getWeekDays(date))
                break
            case 1:
                setRangeDates(DateUtils.addDayToRange(date, rangeDates))
                break;
            case 2:
                let days = singleDates
                if (selected) {
                    days = days.delete(moment(date).format('YYYY-MM-DD'))
                } else {
                    days = days.add(moment(date).format('YYYY-MM-DD'))
                }
                setSingleDates(days)
                break;
        }
    };

    const createFdl = (generateFdl, generatePur, commission, fdl, lockData) => {
        return new Promise((resolve, reject) => {
                let days
                switch (datesSelectionType) {
                    case 0:
                        days = getDates(rangeDates.from, rangeDates.to)
                        break
                    case 1:
                        if (rangeDates.from !== null)
                            days = getDates(rangeDates.from, rangeDates.to)
                        break;
                    case 2:
                        days = singleDates.toJS()
                        break;
                }
                let _commission
                if (commission) {
                    _commission = [commission]
                } else if (selectedComm.size !== printOptions.commissionList.length) {
                    _commission = selectedComm.toJS()
                }

                props.printFdl(days, _commission, fdl, generalDescription, generateFdl, generatePur, expAddData.toJS(), false, lockData)
                    .then(() => resolve())
                    .catch(() => reject())
            }
        )
    }

    const confirmData = () => {
        return new Promise((resolve, reject) => {
                let days
                switch (datesSelectionType) {
                    case 0:
                        days = getDates(rangeDates.from, rangeDates.to)
                        break
                    case 1:
                        if (rangeDates.from !== null)
                            days = getDates(rangeDates.from, rangeDates.to)
                        break;
                    case 2:
                        days = singleDates.toJS()
                        break;
                }
                props.confirmData(days)
                    .then(() => resolve())
                    .catch(() => reject())
            }
        )
    }

    const dataOptCloseHandle = (event) => {
        if (dataAnchorRef.current && dataAnchorRef.current.contains(event.target)) {
            return;
        }
        setSubDataOptMenu(false)
    };

    let actDaySelection
    let modifiers = {};
    let enableGeneration
    switch (datesSelectionType) {
        case 0:
        case 1:
            const {from, to} = rangeDates;
            actDaySelection = [from, {from, to}]
            modifiers = {start: from, end: to};
            enableGeneration = from !== undefined && to !== undefined
            break;
        case 2:
            actDaySelection = singleDates.map(d => moment(d, 'YYYY-MM-DD').toDate()).toJS()
            modifiers = {};
            enableGeneration = actDaySelection.length !== 0
            break;
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            className={'fdlDialog'}
            maxWidth={"xl"}
        >
            <DialogTitle>Creazione FDL</DialogTitle>
            <DialogContent className={'content'}>
                <div className={'main'}>
                    <div className={'daysSelection'}>
                        <ToggleButtonGroup
                            value={datesSelectionType}
                            exclusive
                            onChange={(e, newValue) => setDatesSelectionType(newValue)}
                            className={'toggleButton'}
                            color={"secondary"}
                        >
                            <ToggleButton value={0}>
                                Settimanale
                            </ToggleButton>
                            <ToggleButton value={1}>
                                Da - A
                            </ToggleButton>
                            <ToggleButton value={2}>
                                Libero
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <DayPicker
                            className={'Selectable'}
                            localeUtils={MomentLocaleUtils}
                            locale={'it'}
                            fixedWeeks
                            selectedDays={actDaySelection}
                            showWeekNumbers
                            showOutsideDays
                            modifiers={modifiers}
                            onDayClick={weekDayChangeHandle}
                            modifiersClassNames={{
                                selected: theme.palette.primary.main,
                                today: theme.palette.primary.main
                            }}

                        />
                    </div>
                    <Divider orientation={"vertical"} variant={"middle"} flexItem/>
                    <div className={'commSelection'}>

                        <div variant={"outlined"} className={'listContainer'}>
                            <Typography className={'title'}>Commesse</Typography>
                            <div className={'options'}>
                                {printOptionsState === 1 ?
                                    <>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                    </> :
                                    printOptions.commissionList.map(f => {
                                        return <>
                                            <div className={'commission'} key={f}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            color={"secondary"}
                                                            onChange={e => {
                                                                const value = e.target.checked
                                                                if (value) {
                                                                    setSelectedComm(v => v.add(f))
                                                                } else {
                                                                    setSelectedComm(v => v.delete(f))
                                                                }
                                                            }} defaultChecked={selectedComm.includes(f)} name="gilad"/>
                                                    }
                                                    label={f}
                                                />
                                                <IconButton color="primary" component="label">
                                                    <PrintRoundedIcon
                                                        color={"secondary"}
                                                        onClick={() => createFdl(true, true, f)}
                                                    />
                                                </IconButton>
                                            </div>
                                            <Divider flexItem variant={"fullWidth"}/>
                                        </>
                                    })}
                            </div>
                        </div>

                        <div variant={"outlined"} className={'listContainer'}>
                            <Typography className={'title'}>Fdl</Typography>
                            <div className={'options'}>
                                {printOptionsState === 1 ?
                                    <>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                        <Skeleton variant={"rounded"} height={40} style={{marginBottom: '5px'}}/>
                                    </> :
                                    printOptions.fdlList.map(f => {
                                        return <div className={'commission'} key={f}>
                                            <div>

                                                <Typography>{f}</Typography>
                                            </div>
                                            <IconButton
                                                color="primary"
                                                component="label"
                                                onClick={() => createFdl(true, true, null, f)}
                                            >
                                                <PrintRoundedIcon color={"secondary"}/>
                                            </IconButton>
                                        </div>
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
                <Divider style={{margin: '20px 0 10px 0'}} variant={"fullWidth"}/>
                <div className={'additionalData'}>
                    <TabContext value={actTabAddData}>
                        <TabList onChange={(event, newValue) => setActTabAddData(newValue)} indicatorColor="secondary"
                                 textColor={'secondary'}>
                            <Tab label="Note" value="ore"/>
                            <Divider flexItem orientation={"vertical"} variant={"fullWidth"}/>
                            <Tab label="Contanti" value="contanti"/>
                            <Tab label="Cambi Euro" value="cambiEuro"/>
                            <Tab label="Valuta locale" value="currency"/>
                            <Tab label="Cambi valuta locale" value="cambiVl"/>
                        </TabList>
                        <TabPanel value="ore" className={'tabPanel'}>
                            <TextField
                                className={'mainDescription'}
                                variant={"outlined"}
                                label={'Note Foglio ore'}
                                fullWidth
                                margin={"dense"}
                                size={"small"}
                                color={'secondary'}
                                multiline={true}
                                rows={5}
                                // maxRows={5}
                                value={generalDescription}
                                onChange={(e) => setGeneralDescription(e.target.value)}

                            />
                        </TabPanel>
                        <TabPanel value="currency" className={'tabPanel'}>
                            <TextField
                                className={'mainDescription'}
                                variant={"outlined"}
                                label={'Valuta locale'}
                                margin={"dense"}
                                size={"small"}
                                color={'secondary'}
                                value={expAddData.get('currency')}
                                onChange={e => setExpAddData(old => old.updateIn([`currency`], () => e.target.value))}

                            />
                        </TabPanel>
                        <TabPanel value="cambiEuro" className={'tabPanel'}>
                            <table className={'table'}>
                                {expAddData.get('exc_e_vl').map((s, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>Euro</td>
                                            <TextField
                                                type={'number'}
                                                value={s.get('euro')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc_e_vl`, index, 'euro'], () => parseFloat(e.target.value)))}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        setExpAddData(old => old.updateIn([`exc_e_vl`, index, 'euro'], () => 0))
                                                    }
                                                }}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}
                                            />
                                            <td>Valuta locale</td>
                                            <TextField
                                                type={'number'}
                                                value={s.get('vl')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc_e_vl`, index, 'vl'], () => parseFloat(e.target.value)))}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        setExpAddData(old => old.updateIn([`exc_e_vl`, index, 'vl'], () => 0))
                                                    }
                                                }}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}/>
                                        </tr>
                                    )
                                })

                                }
                            </table>
                        </TabPanel>
                        <TabPanel value="cambiVl" className={'tabPanel'}>
                            <table className={'table'}>
                                {expAddData.get('exc_vl_e').map((s, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>Valuta locale</td>
                                            <TextField
                                                type={'number'}
                                                value={s.get('vl')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc_vl_e`, index, 'vl'], () => parseFloat(e.target.value)))}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        setExpAddData(old => old.updateIn([`exc_vl_e`, index, 'vl'], () => 0))
                                                    }
                                                }}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}/>
                                            <td>Euro</td>
                                            <TextField
                                                type={'number'}
                                                value={s.get('euro')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc_vl_e`, index, 'euro'], () => parseFloat(e.target.value)))}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        setExpAddData(old => old.updateIn([`exc_vl_e`, index, 'euro'], () => 0))
                                                    }
                                                }}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}/>
                                        </tr>
                                    )
                                })

                                }
                            </table>
                        </TabPanel>
                        <TabPanel value="contanti" className={'tabPanel'}>
                            <table className={'table'}>
                                {expAddData.get('exc').map((s, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>Valuta</td>
                                            <TextField
                                                value={s.get('currency')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc`, index, 'currency'], () => e.target.value))}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}/>
                                            <td>Euro</td>
                                            <TextField
                                                type={'number'}
                                                value={s.get('tot')}
                                                onChange={e => setExpAddData(old => old.updateIn([`exc`, index, 'tot'], () => parseFloat(e.target.value)))}
                                                onBlur={e => {
                                                    if (isNaN(parseFloat(e.target.value))) {
                                                        setExpAddData(old => old.updateIn([`exc`, index, 'tot'], () => 0))
                                                    }
                                                }}
                                                size={"small"}
                                                margin={"dense"}
                                                className={'txtInput'}/>
                                        </tr>
                                    )
                                })

                                }
                            </table>
                        </TabPanel>
                    </TabContext>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleClose()} variant={'outlined'} color="secondary"
                        style={{margin: 'auto auto auto 10px'}}>
                    Chiudi
                </Button>
                <ButtonGroup
                    variant={"outlined"}
                    ref={dataAnchorRef}
                    color={"secondary"}
                    className={`btnGrpAction ${subDataAnimation && 'rainbow_1'}`}
                >
                    <LoadingButton
                        onClick={() => {

                            setSubDataAnimation(true)
                            createFdl(true, true).finally(() => setSubDataAnimation(false))
                        }}
                        loading={subDataAnimation}
                        loadingPosition="end"
                        variant={"outlined"}
                        disabled={!enableGeneration}
                        endIcon={<PictureAsPdfRoundedIcon/>}
                    >
                        <Tooltip
                            title={"Genera PDF ore e spese senza inviare i dati all'amministrazione, ATTENZIONE BISOGNA SEMPRE INVIARE I DATI ALL'AMMISNISTRAZIONE"}
                            style={{marginRight: '10px'}}
                        >
                            <HelpOutlineRoundedIcon/>
                        </Tooltip>
                        Genera solo pdf
                    </LoadingButton>

                    <Button
                        size="small"
                        onClick={() => setSubDataOptMenu(old => !old)}
                    >
                        <ArrowDropDownIcon/>
                    </Button>
                </ButtonGroup>
                <Popper
                    style={{zIndex: 1000000000, width: "263px"}}
                    open={subDataOptMenu}
                    anchorEl={dataAnchorRef.current}
                    transition
                    placement={'bottom-start'}
                >
                    {({TransitionProps, placement}) => (<Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={dataOptCloseHandle}>
                                <MenuList id="split-button-menu">
                                    <MenuItem
                                        key={'Solo pdf spese'}
                                        onClick={(event) => {
                                            setSubDataOptMenu(false)
                                            setSubDataAnimation(true)
                                            props.printClean(1).finally(() => setSubDataAnimation(false))
                                        }}
                                    >
                                        Crea pdf spese vuoto
                                    </MenuItem>
                                    <MenuItem
                                        key={'Solo pdf spese'}
                                        onClick={(event) => {
                                            setSubDataOptMenu(false)
                                            setSubDataAnimation(true)
                                            props.printClean(0).finally(() => setSubDataAnimation(false))
                                        }}
                                    >
                                        Crea pdf ore vuoto
                                    </MenuItem>
                                    <MenuItem
                                        key={'Solo pdf spese'}
                                        onClick={(event) => {
                                            setSubDataOptMenu(false)
                                            setSubDataAnimation(true)
                                            createFdl(false, true).finally(() => setSubDataAnimation(false))
                                        }}
                                        disabled={!enableGeneration}
                                    >
                                        Crea pdf spese
                                    </MenuItem>
                                    <MenuItem
                                        key={'Solo pdf ore'}
                                        onClick={(event) => {
                                            setSubDataOptMenu(false)
                                            setSubDataAnimation(true)
                                            createFdl(true, false).finally(() => setSubDataAnimation(false))
                                        }}
                                        disabled={!enableGeneration}
                                    >
                                        Crea pdf Ore
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>)}
                </Popper>

                <LoadingButton
                    variant={"contained"}
                    onClick={() => {
                        setDataAnimation(true)
                        createFdl(true, true, null, null, true)
                            .finally(() => setDataAnimation(false))
                    }}
                    className={`${dataAnimation && 'rainbow_1'}`}
                    loading={dataAnimation}
                    loadingPosition="end"
                    // style={{width:190}}
                    endIcon={<SendRoundedIcon/>}
                    disabled={!enableGeneration}
                >
                    <Tooltip
                        title={"Genera PDF ore e spese e invia i dati all'amministrazione"}
                        style={{marginRight: '10px'}}
                    >
                        <HelpOutlineRoundedIcon/>
                    </Tooltip>
                    Genera pdf
                </LoadingButton>
                <LoadingButton
                    variant={"contained"}
                    onClick={() => {
                        setDataConfirmationAnimation(true)
                        confirmData()
                            .finally(() => setDataConfirmationAnimation(false))
                    }}
                    className={`${dataConfirmationAnimation && 'rainbow_1'}`}
                    loading={dataConfirmationAnimation}
                    loadingPosition="end"
                    // style={{width:190}}
                    endIcon={<SendRoundedIcon/>}
                    disabled={!enableGeneration}
                >
                    <Tooltip
                        title={"Conferma i dati all'amministrazione senza stampare gli FDL"}
                        style={{marginRight: '10px'}}
                    >
                        <HelpOutlineRoundedIcon/>
                    </Tooltip>
                    Conferma dati
                </LoadingButton>

            </DialogActions>
        </Dialog>)

}

export default FdlDialog;
