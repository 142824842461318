import React from 'react';
import classes from './DetailPanelContent.module.scss';
import {Paper, Typography} from '@mui/material'
import {DataGridPremium, GRID_DETAIL_PANEL_TOGGLE_COL_DEF} from "@mui/x-data-grid-premium";
import {totCommHour} from "../../Library/Utils/Utils";


const DetailPanelContent = ({row}) => {
    console.debug(row)

    const worktimeColumns = [
        {field: 'start', headerName: 'Inizio', width:120},
        {field: 'end', headerName: 'Fine', width:120},
        {field: 'tot', width:120,
            headerName: 'Totale', valueGetter: (params) => {
                return Math.round(totCommHour(params.row.start, params.row.end) * 100) / 100

            }
        }
    ];

    const travelsColumns = [
        {field: 'start', headerName: 'Inizio', width:120},
        {field: 'end', headerName: 'Fine', width:120},
        {field: 'machine', headerName: 'Macchina', width:140},
        {field: 'km', headerName: 'Km', width:120},
        {field: 'highway', headerName: 'Autostrada', width:160},
        {field: 'tot', width:120,
            headerName: 'Totale', valueGetter: (params) => {
                return Math.round(totCommHour(params.row.start, params.row.end) * 100) / 100

            }
        }
    ];

    const purchasesColumns = [
        {field: 'description', headerName: 'Descrizione', width:300},
        {field: 'total', headerName: 'Totale', width:120},
        {field: 'method', headerName: 'Metodo', width:150},
        {field: 'note', headerName: 'Note', width:300},
    ];

    return <Paper className={classes.detailPanelContent}>
        <div className={classes.firstRow}>

            {
                row.worktime.length > 0 &&
                <div className={classes.table} style={{height: `${60 + Math.min(row.worktime.length, 4) * 56}px`, minWidth:'360px'}}>
                    <Typography variant={'h6'}>Fasce lavorative</Typography>
                    < DataGridPremium
                        columns={worktimeColumns}
                        rows={row.worktime}
                        hideFooter
                    />
                </div>
            }
            {
                row.travels.length > 0 &&
                <div className={classes.table}
                     style={{minWidth: '780px', height: `${60 + Math.min(row.travels.length, 4) * 56}px`}}>
                    <Typography variant={'h6'}>Fasce di viaggio</Typography>
                    < DataGridPremium
                        columns={travelsColumns}
                        rows={row.travels}
                        hideFooter
                    />
                </div>
            }
        {
            row.purchases.length > 0 &&
            <div className={classes.table} style={{height: `${60 + Math.min(row.purchases.length, 4) * 56}px`, minWidth: '870px'}}>
                <Typography variant={'h6'}>Pagamenti</Typography>
                < DataGridPremium
                    columns={purchasesColumns}
                    rows={row.purchases}
                    hideFooter
                />
            </div>
        }
        </div>
    </Paper>
}

export default DetailPanelContent;