import React, {useState} from "react"
import "./WorkTime.scss"
import Fab from '@mui/material/Fab';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import Typography from "@mui/material/Typography";
import {totCommHour} from "../../../Library/Utils/Utils";
import {Tooltip} from "@mui/material";
import {withMedia} from "react-media-query-hoc";
import MoreTimeIcon from "../../../Library/Icons/MoreTimeIcon";
import LessTimeIcon from "../../../Library/Icons/LessTimeIcon";

const WorkTime = ({
                      data,
                      idxDay,
                      idxWd,
                      idxWt,
                      idWd,
                      onWorkTimeChange,
                      onWorkTimeAdd,
                      onWorkTimeDelete,
                      media,
                      lockedData,
                        disableDelete
                  }) => {

    const [fieldChanged, setFieldChanged] = useState(false)

    return (
        <div className="worktime">
            <TextField
                id={"timeStart-" + data.get('id')}
                label="Inizio"
                type="time"
                color={"secondary"}
                variant="outlined"
                margin="dense" size="small"
                disabled={lockedData}
                defaultValue={data.get('start')}
                className={'timePicker'}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                onChange={() => {
                    setFieldChanged(true)
                }}
                onBlur={(event) => {
                    if (!fieldChanged) return
                    setFieldChanged(false)
                    onWorkTimeChange(event.target.value, idxDay, idxWd, idxWt, 'start', data.get('id'))
                }}
            />
            <TextField
                id={"timeEnd-" + data.get('id')}
                label="Fine"
                type="time"
                variant="outlined"
                color={"secondary"}
                margin="dense" size="small"
                disabled={lockedData}
                defaultValue={data.get('end')}
                className={'timePicker'}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                }}
                onChange={() => {
                    console.debug('fieldChanged')
                    setFieldChanged(true)
                }}
                onBlur={(event) => {
                    if (!fieldChanged) return
                    setFieldChanged(false)
                    onWorkTimeChange(event.target.value, idxDay, idxWd, idxWt, 'end', data.get('id'))
                }}
            />
            {
                (media.genPc || media.tablet || media.mobile) &&
                <Typography style={{
                    flex: '150 15',
                    justifyContent: 'flex-start'
                }}>{`Tot. Ore ${Math.round(totCommHour(data.get('start'), data.get('end')) * 100) / 100}`}</Typography>
            }
            <div className={'subRow'} style={{margin: '0 0 0 auto', justifyContent: 'flex-end'}}>
                <Tooltip title={'Aggiungi fascia lavorativa'}>
                    <>
                        <Fab color="secondary" className={'circButton'}
                             disabled={lockedData}
                             onClick={() => onWorkTimeAdd(idxDay, idxWd, idWd)}>
                            <MoreTimeIcon style={{top: '7px', left: '9px', position: 'absolute'}}/>
                        </Fab>
                    </>
                </Tooltip>
                <Tooltip title={'Elimina fascia lavorativa'}>
                    <>
                        <Fab color="secondary" className={'circButton'}
                             disabled={lockedData || disableDelete}
                             onClick={() => onWorkTimeDelete(idxDay, idxWd, idxWt, data.get('id'))}>
                            <LessTimeIcon style={{top: '7px', left: '9px', position: 'absolute'}}/>
                        </Fab>
                    </>
                </Tooltip>
            </div>
        </div>
    )

};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(withMedia(WorkTime))

