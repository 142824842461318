import {Autocomplete, Button, Card, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import style from "../WdExport.module.scss";
import Divider from "@mui/material/Divider";
import DayPicker, {DateUtils} from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import React, {useState} from "react";
import moment from "moment/moment";
import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import apiV2 from "../../../apiV2";
import {ENQUEUE_SNACKBAR, enqueueSnackbar} from "../../../store/actions/action";
import {fromJS, List} from "immutable";
import {getDates} from "../../Library/Utils/Utils";
import {downloadFdl, generateFdl} from "../../../store/actions/WorkData";

const LockData = () => {
    const theme = useTheme()
    const dispatch = useDispatch()

    const users = useSelector(state => state.mng_data.users.filter(u=>u.get('enable')));


    const [datesSelectionType, setDatesSelectionType] = useState(0)
    const [rangeDates, setRangeDates] = useState({from: undefined, to: undefined})
    const [lockExcludedUser, setLockExcludedUser] = useState([])
    const [unlockFilteredUser, setUnlockFilteredUser] = useState([])
    const [pdfFilteredUser, setPdfFilteredUser] = useState([])
    const [actionType, setActionType] = useState(0)

    function getWeekDays(day) {
        let startWeek = moment(day).startOf('week')
        if (moment(day).month() !== startWeek.month() || moment(day).year() !== startWeek.year()) {
            startWeek = moment(day).startOf('month')
        }
        let endWeek = moment(day).endOf('week')
        if (endWeek.month() !== startWeek.month() || endWeek.year() !== startWeek.year()) {
            endWeek = moment(day).endOf('month')
        }
        return {from: startWeek.toDate(), to: endWeek.toDate()};
    }

    const {from, to} = rangeDates;
    const actDaySelection = [from, {from, to}]
    const modifiers = {start: from, end: to};


    const weekDayChangeHandle = (date, {selected}) => {
        switch (datesSelectionType) {
            case 0:
                setRangeDates(getWeekDays(date))
                break
            case 1:
                setRangeDates(DateUtils.addDayToRange(date, rangeDates))
                break;
        }
    };

    const onLockData = () => {
        console.debug(rangeDates)
        apiV2.post(`lock_data`, {
            start: rangeDates.from.toLocaleDateString('it-IT'),
            end: rangeDates.to.toLocaleDateString('it-IT'),
            excludedUserId: lockExcludedUser
        })
            .then((res) => {
            })
            .catch((err) => {
                let message = 'Failed lock data'
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                }))
            })
    }

    const onUnlockData = () => {
        console.debug(rangeDates)
        apiV2.post(`unlock_data`, {
            start: rangeDates.from.toLocaleDateString('it-IT'),
            end: rangeDates.to.toLocaleDateString('it-IT'),
            filteredUserId: unlockFilteredUser
        })
            .then((res) => {
            })
            .catch((err) => {
                let message = 'Failed unlock data'
                dispatch(enqueueSnackbar({
                    message: message,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error',
                        autoHideDuration: 3000,
                    },
                }))
            })
    }

    const onPdfExport = () => {
        console.debug(rangeDates)
        console.debug(pdfFilteredUser)
        users.filter(u=>pdfFilteredUser.includes(u.get('id')) || pdfFilteredUser.length === 0).map(u=>{
        console.debug(u, u.id)
        dispatch(generateFdl(getDates(rangeDates.from, rangeDates.to), null, null, null, true, true, {}, true, false, u.get('id')))
            .then(res => {
                console.debug(res.data["file"].length)
                if (res.data["file"].length === 0) {
                    dispatch({
                        type: ENQUEUE_SNACKBAR,
                        notification: {
                            message: `Nessun dato trovato ${u.get('name')} ${u.get('surname')}`,
                            key: new Date().getTime() + u.get('id'),
                            options: {
                                variant: 'warning',
                                autoHideDuration: 3000,
                            },
                        },
                    })
                    return
                }
                for (let file_name in res.data["file"]) {
                    dispatch(downloadFdl(res.data["file"][file_name]))
                        .then(res => {
                            let headerLine
                            let startFileNameIndex
                            let filename
                            headerLine = res.config.url
                            startFileNameIndex = headerLine.indexOf('=') + 1
                            filename = headerLine.substring(startFileNameIndex)
                            const url = window.URL.createObjectURL(new Blob([res.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename + '.pdf');
                            document.body.appendChild(link);
                            link.click();
                        })
                        .catch(err => {
                        })
                }
            }
        )
        })
    }

    return <Card className={style.card}>
        <Typography variant={"h6"}>Blocco Dati</Typography>
        <Divider flexItem/>
        <ToggleButtonGroup
            value={datesSelectionType}
            exclusive
            onChange={(e, newValue) => setDatesSelectionType(newValue)}
            color={"secondary"}
            fullWidth
            size={"small"}

        >
            <ToggleButton value={0}>
                Settimanale
            </ToggleButton>
            <ToggleButton value={1}>
                Da - A
            </ToggleButton>
        </ToggleButtonGroup>
        <DayPicker
            className={'Selectable'}
            localeUtils={MomentLocaleUtils}
            locale={'it'}
            fixedWeeks
            selectedDays={actDaySelection}
            showWeekNumbers
            showOutsideDays
            modifiers={modifiers}
            onDayClick={weekDayChangeHandle}
            modifiersClassNames={{
                selected: theme.palette.primary.main,
                today: theme.palette.primary.main
            }}
        />
        <ToggleButtonGroup
            value={actionType}
            exclusive
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    setActionType(newValue)
                }
            }}
            color={"secondary"}
            fullWidth
            size={"small"}
        >
            <ToggleButton value={0}>
                Lock
            </ToggleButton>
            <ToggleButton value={1}>
                Unlock
            </ToggleButton>
            <ToggleButton value={2}>
                PDF export
            </ToggleButton>
        </ToggleButtonGroup>
        {actionType === 0 &&
            <>
                <Autocomplete
                    limitTags={2}
                    multiple
                    value={lockExcludedUser}
                    options={users?.sortBy(u => u.get('surname') + u.get('name')).map(u => u.get('id')).toJS()}
                    onChange={(e, v, r) => setLockExcludedUser(v)}
                    getOptionLabel={(option) => {
                        const user = users.find(u => u.get('id') === option)
                        return `${user.get('surname')} ${user.get('name')}`
                    }}
                    fullWidth
                    color={"secondary"}
                    renderInput={(params) => <TextField {...params}
                                                        multiline
                                                        maxRows={3}
                                                        rows={3}
                                                        variant={'outlined'}
                                                        label="Escludi"
                                                        helperText={"Seleziona gli utenti da escludere dal blocco (lasciare vuoto per tutti)"}
                    />}
                />
                <Button variant={'contained'} fullWidth style={{margin: 'auto auto 0 auto'}}
                        onClick={onLockData}>Lock</Button>
            </>
        }
        {actionType === 1 &&
            <>
                <Autocomplete
                    limitTags={2}
                    multiple
                    value={unlockFilteredUser}
                    options={users?.sortBy(u => u.get('surname') + u.get('name')).map(u => u.get('id')).toJS()}
                    onChange={(e, v, r) => setUnlockFilteredUser(v)}
                    getOptionLabel={(option) => {
                        const user = users.find(u => u.get('id') === option)
                        return `${user.get('surname')} ${user.get('name')}`
                    }}
                    fullWidth
                    color={"secondary"}
                    renderInput={(params) => <TextField {...params}
                                                        multiline
                                                        maxRows={3}
                                                        rows={3}
                                                        color={"secondary"}
                                                        variant={'outlined'}
                                                        label="Filtra"
                                                        helperText={"Sblocca i dati degli utenti selezionati (lasciare vuoto per tutti)"}

                    />}
                />
                <Button variant={'contained'} fullWidth style={{margin: 'auto auto 0 auto'}}
                        onClick={onUnlockData}>Unlock</Button>
            </>
        }
        {actionType === 2 &&
            <>
                <Autocomplete
                    limitTags={2}
                    multiple
                    value={pdfFilteredUser}
                    options={users?.sortBy(u => u.get('surname') + u.get('name')).map(u => u.get('id')).toJS()}
                    onChange={(e, v, r) => setPdfFilteredUser(v)}
                    getOptionLabel={(option) => {
                        const user = users.find(u => u.get('id') === option)
                        return `${user.get('surname')} ${user.get('name')}`
                    }}
                    fullWidth
                    color={"secondary"}
                    renderInput={(params) => <TextField {...params}
                                                        multiline
                                                        maxRows={3}
                                                        rows={3}
                                                        color={"secondary"}
                                                        variant={'outlined'}
                                                        label="Filtra"
                                                        helperText={"Stampa i pdf solo degli utenti selezionati (lasciare vuoto per tutti)"}

                    />}
                />
                <Button variant={'contained'} fullWidth style={{margin: 'auto auto 0 auto'}} onClick={onPdfExport}>Print</Button>
            </>
        }
    </Card>
}

export default LockData;
